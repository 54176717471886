@use 'sass:map';
@use '@angular/material' as mat;
@use "@sng/sng-front-common-style/sass/abstracts/colors" as *;

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core;

// TODO typography https://material.angular.io/guide/typography
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$portal-primary-palette: (
  100: $color-broker-300,
  500: $color-broker-500,
  700: $color-broker-700,
  contrast: (
    100: $light-primary-text,
    500: $light-primary-text,
    700: $light-primary-text,
  )
);

$voting-portal-front-primary: mat.m2-define-palette($portal-primary-palette);

// accent and warn are required for theme define, but won't be used (yet)

$voting-portal-front-accent: mat.m2-define-palette(mat.$m2-pink-palette);

// The warn palette is optional (defaults to red).

$voting-portal-front-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".

$voting-portal-front-theme: mat.m2-define-light-theme((
  color: (
    primary: $voting-portal-front-primary,
    accent: $voting-portal-front-accent,
    warn: $voting-portal-front-warn,
  )
));

@include mat.all-component-themes(
    $voting-portal-front-theme
);

.color-primary {
  color: mat.get-theme-color($voting-portal-front-theme, primary, 500);
}

.bg-primary {
  background-color: mat.get-theme-color($voting-portal-front-theme, primary, 500);
}
