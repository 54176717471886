// Typography vars

$font-family--hk-grotesk: hk grotesk, "HK Grotesk", sans-serif;

$font-family--hk-grotesk-plus-additional: "HK Grotesk", "HK Grotesk", roboto, "Helvetica Neue", sans-serif;


$font-sm: 12px;

$font-md: 14px;

$font-lg: 18px;

$font-xl: 24px;

$font-2xl: 32px;


$leading-sm: 16px;

$leading-md: 20px;

$leading-lg: 24px;

$leading-xlg: 28px;

$leading-xl: 32px;

$leading-2xl: 40px;


$font-thin: 100;

$font-extra_light: 200;

$font-light: 300;

$font-normal: 400;

$font-medium: 500;

$font-semi_bold: 600;

$font-bold: 700;

$font-extra_bold: 800;

$font-black: 900;
