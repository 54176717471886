// Color vars

$color-black: #000;

$color-black-5: rgb(0 0 0 / 5%);

$color-white: #FFF;

$color-white-90: rgb(255 255 255 / 90%);

$color-white-80: rgb(255 255 255 / 80%);

$color-white-70: rgb(255 255 255 / 70%);

$color-white-60: rgb(255 255 255 / 60%);

$color-white-50: rgb(255 255 255 / 50%);

$color-white-40: rgb(255 255 255 / 40%);

$color-white-30: rgb(255 255 255 / 30%);

$color-white-20: rgb(255 255 255 / 20%);

$color-white-15: rgb(255 255 255 / 15%);

$color-white-10: rgb(255 255 255 / 10%);

$color-white-07: rgb(255 255 255 / 7%);

// Grayscale

$color-grayscale-50: #F5F5F5;

$color-grayscale-100: #E6E6E6;

$color-grayscale-100-60: rgb(230 230 230 / 60%);

$color-grayscale-100-70: rgb(230 230 230 / 70%);

$color-grayscale-200: #DCDCDC;

$color-grayscale-300: #D2D2D2;

$color-grayscale-300-15: rgb(210 210 210 / 15%);

$color-grayscale-400: #B4B4B4;

$color-grayscale-500: #A0A0A0;

$color-grayscale-500-07: rgb(160 160 160 / 7%);

$color-grayscale-600: #8C8C8C;

$color-grayscale-600-60: rgb(140 140 140 / 60%);

$color-grayscale-700: #787878;

$color-grayscale-800: #505050;

$color-grayscale-900: #282828;

$color-grayscale-node-element: #ECECEC;

$color-grayscale-slider: #D9D9D9;

// Key

$color-key-50: #F2F4FE;

$color-key-100: #DDE3FC;

$color-key-200: #C2CBF4;

$color-key-300: #97A7F2;

$color-key-400: #697BDF;

$color-key-400-60: #697BDF33;

$color-key-400-20: #697BDF33;

$color-key-500: #5063D5;

$color-key-500-60: rgb(80 99 213 / 60%);

$color-key-500-40: rgb(80 99 213 / 40%);

$color-key-500-7: rgb(80 99 213 / 7%);

$color-key-600: #3A4AB7;

$color-key-600-15: rgb(58 74 183 / 15%);

$color-key-700: #283599;

$color-key-800: #19237B;

$color-key-900: #0F1666;

// Accent

$color-accent-50: #FEF6DD;

$color-accent-100: #FEF2CE;

$color-accent-200: #FDE29D;

$color-accent-300: #F9CC6C;

$color-accent-400: #EFA43A;

$color-accent-400-60: #EFA43A99;

$color-accent-400-20: #EFA43A33;

$color-accent-500: #EB930E;

$color-accent-500-60: #EB930E99;

$color-accent-500-40: #EB930E66;

$color-accent-500-7: #EB930E11;

$color-accent-600: #CA750A;

$color-accent-600-15: #CA750A26;

$color-accent-700: #A95B07;

$color-accent-800: #884304;

$color-accent-900: #703302;

// Success

$color-success-50: #E7FDEB;

$color-success-100: #D0FBD8;

$color-success-200: #A2F8BA;

$color-success-300: #71ECA0;

$color-success-400: #4CD990;

$color-success-400-60: #4CD99099;

$color-success-500: #1AC17B;

$color-success-500-60: #1AC17B99;

$color-success-500-7: #1AC17B11;

$color-success-600: #13A577;

$color-success-600-60: #13A57799;

$color-success-600-20: #13A57733;

$color-success-600-15: #13A57726;

$color-success-700: #0D8A6F;

$color-success-700-40: #0D8A6F66;

$color-success-800: #086F63;

$color-success-900: #045C5A;

// Danger

$color-danger-50: #FEF5F1;

$color-danger-100: #FEECE3;

$color-danger-100: #FEECE3;

$color-danger-200: #FDD5C7;

$color-danger-300: #F9B7A9;

$color-danger-400: #F18984;

$color-danger-400-60: #F1898499;

$color-danger-500: #ED6F6F;

$color-danger-500-60: #ED6F6F99;

$color-danger-500-07: #ED6F6F11;

$color-danger-600: #CB515B;

$color-danger-600-60: #CB515B99;

$color-danger-600-20: #CB515B33;

$color-danger-600-15: #CB515B33;

$color-danger-700: #AA374B;

$color-danger-700-40: #AA374B66;

$color-danger-800: #89233D;

$color-danger-900: #711535;

// Broker

$color-broker-50: #EEF6F8;

$color-broker-100: #DDEEF2;

$color-broker-200: #ABDBE4;

$color-broker-200-7: rgb(191 217 226 / 7%);

$color-broker-300: #8CCAD8;

$color-broker-400: #62BDCD;

$color-broker-400-20: rgb(98 189 205 / 20%);

$color-broker-400-60: rgb(98 189 205 / 60%);

$color-broker-500: #1EA1B8;

$color-broker-500-7: rgb(30 161 183 / 7%);

$color-broker-500-10: rgb(30 161 183 / 10%);

$color-broker-500-25: rgb(30 161 183 / 25%);

$color-broker-500-40: rgb(30 161 183 / 40%);

$color-broker-500-60: rgb(30 161 184 / 60%);

$color-broker-600: #3F8496;

$color-broker-600-15: rgb(63 132 150 / 15%);

$color-broker-700: #316A79;

$color-broker-800: #25505B;

$color-broker-900: #19323B;

// Insurer

$color-insurer-50: #F2E9ED;

$color-insurer-100: #E6D4DC;

$color-insurer-200: #CCA9B9;

$color-insurer-300: #B37E96;

$color-insurer-400: #945774;

$color-insurer-400-20: rgb(148 87 116 / 20%);

$color-insurer-400-60: rgb(148 87 116 / 60%);

$color-insurer-500: #753C52;

$color-insurer-500-7: rgb(117 60 82 / 7%);

$color-insurer-500-10: rgb(117 60 82 / 10%);

$color-insurer-500-25: rgb(117 60 82 / 25%);

$color-insurer-500-40: rgb(117 60 82 / 40%);

$color-insurer-500-60: rgb(117 60 82 / 60%);

$color-insurer-600: #5B3544;

$color-insurer-600-15: rgb(91 53 68 / 15%);

$color-insurer-700: #492A36;

$color-insurer-800: #372029;

$color-insurer-900: #24151B;

// Platform

$color-platform-50: #FEF4E6;

$color-platform-100: #FDEACD;

$color-platform-200: #FCD09C;

$color-platform-300: #F8AE6B;

$color-platform-400: #F18D45;

$color-platform-400-20: rgb(241 141 69 / 20%);

$color-platform-400-60: rgb(241 141 69 / 60%);

$color-platform-500: #E95A0C;

$color-platform-500-7: rgb(233 90 12 / 7%);

$color-platform-500-40: rgb(233 90 12 / 40%);

$color-platform-500-60: rgb(233 90 12 / 60%);

$color-platform-600: #C84008;

$color-platform-600-15: rgb(200 64 8 / 15%);

$color-platform-700: #A72A06;

$color-platform-800: #871903;

$color-platform-900: #6F0D02;


$color-inter-pension-50: #EEEFE4;

$color-inter-pension-100: #D4D6BB;

$color-inter-pension-200: #B8BB8E;

$color-inter-pension-300: #9C9F61;

$color-inter-pension-400: #868B3F;

$color-inter-pension-500: #71761D;

$color-inter-pension-600: #696E1A;

$color-inter-pension-700: #5E6315;

$color-inter-pension-800: #545911;

$color-inter-pension-900: #42460A;

// Warning

$color-warning-50: #FEFBE8;

$color-warning-100: #FEF7D1;

$color-warning-200: #FDECA4;

$color-warning-300: #F9DD76;

$color-warning-400: #F4CE54;

$color-warning-500: #EEB61E;

$color-warning-600: #CC9615;

$color-warning-700: #AB780F;

$color-warning-800: #8A5C09;

$color-warning-900: #724805;

// Info

$color-info-50: #E4FAFC;

$color-info-100: #CAF4F9;

$color-info-200: #9DE1EE;

$color-info-300: #61C5E3;

$color-info-400: #34A3D1;

$color-info-500: #0074B3;

$color-info-600: #005A99;

$color-info-700: #004380;

$color-info-800: #002F67;

$color-info-900: #025;


$color-not-unique-answers: #DBD9F2;

$color-not-unique-answers--hover: #D7D4FC;

$color-not-unique-answers--elements: #8075F4;

$color-link-disabled: rgb(0 0 0 / 38%);


$shadow-broker--focus: 0 0 0 .1875rem $color-broker-400-20;

$shadow-platform--focus: 0 0 0 .1875rem $color-platform-400-20;

$shadow-insurer--focus: 0 0 0 .1875rem $color-insurer-400-20;

$shadow-danger--focus: 0 0 0 .1875rem $color-danger-400-60;


$dark-primary-text: rgb(0 0 0 / 87%);

$dark-secondary-text: rgb(0 0 0 / 54%);

$dark-disabled-text: rgb(0 0 0 / 38%);

$dark-dividers: rgb(0 0 0 / 12%);

$dark-focused: rgb(0 0 0 / 12%);

$light-primary-text: #FFF;

$light-secondary-text: rgb(255 255 255 / 7%);

$light-disabled-text: rgb(255 255 255 / 5%);

$light-dividers: rgb(255 255 255 / 12%);

$light-focused: rgb(255 255 255 / 12%);
