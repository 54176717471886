@use "@sng/sng-front-common-style/sass/abstracts/variables" as * with (
 $get-path-to-icons : "@sng/sng-front-common-style/public/img/icons/",
);
@use "@sng/sng-front-common-style/sass/sass-utils/mixins" as *;
@use "@sng/sng-front-common-style/sass/abstracts/typography" as *;
@forward "entry";

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: $font-family--hk-grotesk;
  letter-spacing: initial;
}

.page-loader {
  position: absolute;
  inset: 0;
  min-height: 200px;
  z-index: 10;

  @include flex--center--center;
}
