@use "../sass-utils/mixins" as *;
@use "../abstracts/typography" as *;

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-lowercase {
  &, & span[class*="__primary-text"] {
    text-transform: lowercase;
  }
}

.text-uppercase {
  &, & span[class*="__primary-text"] {
    text-transform: uppercase;
  }
}

.text-capitalize {
  &, & span[class*="__primary-text"] {
    text-transform: capitalize;
  }
}

.text-strong {
  font-weight: $font-medium;
}

.font {
  &.font--bold {
    font-weight: $font-bold;
    font-style: normal;
  }

  &.font--bold-italic {
    font-weight: $font-bold;
    font-style: italic;
  }

  &.font--semibold {
    font-weight: $font-semi_bold;
    font-style: normal;
  }

  &.font--semibold-italic {
    font-weight: $font-semi_bold;
    font-style: italic;
  }

  &.font--medium {
    font-weight: $font-medium;
    font-style: normal;
  }

  &.font--medium-italic {
    font-weight: $font-medium;
    font-style: italic;
  }

  &.font--regular {
    font-weight: $font-normal;
    font-style: normal;
  }

  &.font--italic {
    font-weight: $font-normal;
    font-style: italic;
  }

  &.font--light {
    font-weight: $font-light;
    font-style: normal;
  }

  &.font--light-italic {
    font-weight: $font-light;
    font-style: italic;
  }
}

.header-1,
.header-xl,
.header-2,
.header-xl,
.header-3,
.header-lg,
.header-4,
.header-md {
  font-weight: $font-medium;
  font-style: normal;
}

.header-1,
.header-2xl {
  font-size: $font-2xl;
  line-height: $leading-2xl;
}

.header-2,
.header-xl {
  font-size: $font-xl;
  line-height: $leading-xl;
}

.header-3,
.header-lg {
  font-size: $font-lg;
  line-height: $leading-lg;
}

.header-4,
.header-md {
  font-size: $font-md;
  line-height: $leading-md;
}


.text-overflow--ellipsis {
  display: inline-block;
  width: 100%;

  @include textOverflow;
}
