@font-face {
  font-family: "HK Grotesk";
  src: url("hkgrotesk-bold.eot");
  src:
    url("hkgrotesk-bold.eot?#iefix") format("embedded-opentype"),
    url("hkgrotesk-bold.woff2") format("woff2"),
    url("hkgrotesk-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("hkgrotesk-bolditalic.eot");
  src:
    url("hkgrotesk-bolditalic.eot?#iefix") format("embedded-opentype"),
    url("hkgrotesk-bolditalic.woff2") format("woff2"),
    url("hkgrotesk-bolditalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("hkgrotesk-italic.eot");
  src:
    url("hkgrotesk-italic.eot?#iefix") format("embedded-opentype"),
    url("hkgrotesk-italic.woff2") format("woff2"),
    url("hkgrotesk-italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("hkgrotesk-light.eot");
  src:
    url("hkgrotesk-light.eot?#iefix") format("embedded-opentype"),
    url("hkgrotesk-light.woff2") format("woff2"),
    url("hkgrotesk-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("hkgrotesk-lightitalic.eot");
  src:
    url("hkgrotesk-lightitalic.eot?#iefix") format("embedded-opentype"),
    url("hkgrotesk-lightitalic.woff2") format("woff2"),
    url("hkgrotesk-lightitalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("hkgrotesk-medium.eot");
  src:
    url("hkgrotesk-medium.eot?#iefix") format("embedded-opentype"),
    url("hkgrotesk-medium.woff2") format("woff2"),
    url("hkgrotesk-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("hkgrotesk-mediumitalic.eot");
  src:
    url("hkgrotesk-mediumitalic.eot?#iefix") format("embedded-opentype"),
    url("hkgrotesk-mediumitalic.woff2") format("woff2"),
    url("hkgrotesk-mediumitalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("hkgrotesk-regular.eot");
  src:
    url("hkgrotesk-regular.eot?#iefix") format("embedded-opentype"),
    url("hkgrotesk-regular.woff2") format("woff2"),
    url("hkgrotesk-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("hkgrotesk-semibold.eot");
  src:
    url("hkgrotesk-semibold.eot?#iefix") format("embedded-opentype"),
    url("hkgrotesk-semibold.woff2") format("woff2"),
    url("hkgrotesk-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("hkgrotesk-semibolditalic.eot");
  src:
    url("hkgrotesk-semibolditalic.eot?#iefix") format("embedded-opentype"),
    url("hkgrotesk-semibolditalic.woff2") format("woff2"),
    url("hkgrotesk-semibolditalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}
