@use "../abstracts/colors" as *;
@use "../abstracts/variables" as *;
@use "../sass-utils/mixins" as *;

.icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-color: $color-grayscale-500;
  cursor: pointer;

  &.icon--color--success {
    background-color: $color-success-600;
  }

  &.icon--color--broker {
    background-color: $color-broker-500;
  }

  &.icon--color--insurer {
    background-color: $color-insurer-500;
  }

  &.icon--color--platform {
    background-color: $color-platform-500;
  }

  &.icon--color--info {
    background-color: $color-info-500;
  }

  &.icon--color--danger {
    background-color: $color-danger-500;
  }

  &.icon--color--warning {
    background-color: $color-warning-500;
  }

  &.icon--color--accent {
    background-color: $color-accent-500;
  }

  &.icon--color--gray {
    background-color: $color-grayscale-600;
  }

  &.icon--disabled {
    opacity: .5;
  }

  &.icon--size--12 {
    width: 12px;
    height: 12px;
  }

  &.icon--size--16 {
    width: 16px;
    height: 16px;
  }

  &.icon--size--20 {
    width: 20px;
    height: 20px;
  }

  &.icon--size--24 {
    width: 24px;
    height: 24px;
  }

  &.icon--size--32 {
    width: 32px;
    height: 32px;
  }

  &.icon--size--36 {
    width: 36px;
    height: 36px;
  }

  &.icon--size--48 {
    width: 48px;
    height: 48px;
  }

  &.icon--size--64 {
    width: 64px;
    height: 64px;
  }

  &.icon--size--72 {
    width: 72px;
    height: 72px;
  }

  &.icon--size--96 {
    width: 96px;
    height: 96px;
  }

  &.icon--size--240 {
    width: 240px;
    height: 240px;
  }
}

.icon-image {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
}

.icon--avatar-default {
  @include svg-icon("#{$get-path-to-icons}avatar-default.svg", 24px, 24px, $color-grayscale-500);
}

.icon--unfold_more {
  @include svg-icon("#{$get-path-to-icons}unfold_more.svg", 10px, 16px, $color-grayscale-500);
}

.icon--add {
  @include svg-icon("#{$get-path-to-icons}add.svg", 24px, 24px, $color-grayscale-500);
}

.icon--close {
  @include svg-icon("#{$get-path-to-icons}close.svg", 24px, 24px, $color-grayscale-500);
}

.icon--delete {
  @include svg-icon("#{$get-path-to-icons}delete.svg", 24px, 24px, $color-grayscale-500);
}

.icon--edit {
  @include svg-icon("#{$get-path-to-icons}edit.svg", 24px, 24px, $color-grayscale-500);
}

.icon--search {
  @include svg-icon("#{$get-path-to-icons}search.svg", 24px, 24px, $color-grayscale-500);
}

.icon--faq {
  @include svg-icon("#{$get-path-to-icons}faq.svg", 24px, 24px, $color-grayscale-500);
}

.icon--status-approval,
.icon--status-approved {
  @include svg-icon("#{$get-path-to-icons}status-approved.svg", 24px, 24px, $color-grayscale-500);

  &.icon--status-approved--small {
    width: 20px;
    height: 20px;
  }
}

.icon--status-disapproval {
  @include svg-icon("#{$get-path-to-icons}status-disapproval.svg", 24px, 24px, $color-grayscale-500);
}

.icon--status-error {
  @include svg-icon("#{$get-path-to-icons}status-error.svg", 24px, 24px, $color-grayscale-500);
}

.icon--status-info-renewal {
  @include svg-icon("#{$get-path-to-icons}status-info-renewal.svg", 24px, 24px, $color-grayscale-500);
}

.icon--status-warning {
  @include svg-icon("#{$get-path-to-icons}status-warning.svg", 24px, 24px, $color-grayscale-500);
}

.icon--status-success {
  @include svg-icon("#{$get-path-to-icons}status-success.svg", 24px, 24px, $color-grayscale-500);
}

.icon--status-provision {
  @include svg-icon("#{$get-path-to-icons}status-provision.svg", 24px, 24px, $color-grayscale-500);
}

.icon--status-attention {
  @include svg-icon("#{$get-path-to-icons}status-attention.svg", 24px, 24px, $color-grayscale-500);
}

.icon--status-alert--info {
  @include svg-icon("#{$get-path-to-icons}status-alert.svg", 24px, 24px, $color-grayscale-500);
}

.icon--status-alert--faq {
  @include svg-icon("#{$get-path-to-icons}status-alert-faq.svg", 24px, 24px, $color-grayscale-500);
}

.icon--status-alert--faq-renewal {
  @include svg-icon("#{$get-path-to-icons}status-alert-faq-renewal.svg", 24px, 24px, $color-grayscale-500);
}

.icon--status-approved-square {
  @include svg-icon("#{$get-path-to-icons}status-approved-square.svg", 24px, 24px, $color-grayscale-500);
}

.icon--status-alert-info {
  @include svg-icon("#{$get-path-to-icons}status-alert-info.svg", 24px, 24px, $color-grayscale-500);
}

.icon--status-information-notification {
  @include svg-icon("#{$get-path-to-icons}status-information-notification.svg", 24px, 24px, $color-grayscale-500);
}

.icon--status-faq-help {
  @include svg-icon("#{$get-path-to-icons}status-faq-help.svg", 24px, 24px, $color-grayscale-500);
}

.icon--export {
  @include svg-icon("#{$get-path-to-icons}export.svg", 24px, 24px, $color-grayscale-500);
}

.icon--backtodefault {
  @include svg-icon("#{$get-path-to-icons}backtodefault.svg", 24px, 24px, $color-grayscale-500);
}

.icon--refresh {
  @include svg-icon("#{$get-path-to-icons}refresh.svg", 24px, 24px, $color-grayscale-500);
}

.icon--archive {
  @include svg-icon("#{$get-path-to-icons}archive.svg", 24px, 24px, $color-grayscale-500);
}

.icon--clear {
  @include svg-icon("#{$get-path-to-icons}clear.svg", 24px, 24px, $color-grayscale-500);
}

.icon--call-doc {
  @include svg-icon("#{$get-path-to-icons}call-doc.svg", 24px, 24px, #C4C4C4);

  &.icon--call-doc--small {
    width: 20px;
    height: 20px;
  }
}

.icon--more {
  @include svg-icon("#{$get-path-to-icons}more.svg", 24px, 24px, $color-grayscale-500);

  &.icon--more--small {
    width: 20px;
    height: 20px;
  }
}

.icon--dropdown-collapse-md {
  @include svg-icon("#{$get-path-to-icons}dropdown-collapse-md.svg", 24px, 24px, $color-grayscale-500);
}

.icon--dropdown-expand-md {
  @include svg-icon("#{$get-path-to-icons}dropdown-expand-md.svg", 24px, 24px, $color-grayscale-500);
}

.icon--organization {
  @include svg-icon("#{$get-path-to-icons}organization.svg", 24px, 24px, $color-grayscale-500);
}

.icon--call-processing {
  @include svg-icon("#{$get-path-to-icons}call-processing.svg", 24px, 24px, $color-grayscale-500);
}

.icon--call-waiting {
  @include svg-icon("#{$get-path-to-icons}call-waiting.svg", 24px, 24px, $color-grayscale-500);
}

.icon--call-working {
  @include svg-icon("#{$get-path-to-icons}call-working.svg", 24px, 24px, $color-grayscale-500);
}

.icon--duplicate {
  @include svg-icon("#{$get-path-to-icons}duplicate.svg", 24px, 24px, $color-grayscale-500);
}

.icon--sort {
  @include svg-icon("#{$get-path-to-icons}sort.svg", 24px, 24px, $color-grayscale-500);
}

.icon--sort-ascending {
  @include svg-icon("#{$get-path-to-icons}sort-ascending.svg", 24px, 24px, $color-grayscale-500);
}

.icon--sort-descending {
  @include svg-icon("#{$get-path-to-icons}sort-descending.svg", 24px, 24px, $color-grayscale-500);
}

.icon--settings-admin {
  @include svg-icon("#{$get-path-to-icons}settings-admin.svg", 24px, 24px, $color-grayscale-500);
}

.icon--settings-admin-gray-700 {
  @include svg-icon("#{$get-path-to-icons}settings-admin.svg", 24px, 24px, $color-grayscale-700);
}

.icon--settings {
  @include svg-icon("#{$get-path-to-icons}settings.svg", 24px, 24px, $color-grayscale-500);
}

.icon--arrow-bottom {
  @include svg-icon("#{$get-path-to-icons}arrow-bottom.svg", 24px, 24px, $color-grayscale-500);
}

.icon--arrow-left {
  @include svg-icon("#{$get-path-to-icons}arrow-left.svg", 24px, 24px, $color-grayscale-500);
}

.icon--arrow-right {
  @include svg-icon("#{$get-path-to-icons}arrow-right.svg", 24px, 24px, $color-grayscale-500);
}

.icon--arrows-expand {
  @include svg-icon("#{$get-path-to-icons}arrows-expand.svg", 24px, 24px, $color-black);
}

.icon--arrows-collapse {
  @include svg-icon("#{$get-path-to-icons}arrows-collapse.svg", 24px, 24px, $color-black);
}

.icon--bulb {
  @include svg-icon("#{$get-path-to-icons}bulb.svg", 24px, 24px, $color-grayscale-500);
}

.icon--doc-list {
  @include svg-icon("#{$get-path-to-icons}doc-list.svg", 24px, 24px, $color-grayscale-500);
}

.icon--drag {
  @include svg-icon("#{$get-path-to-icons}drag.svg", 24px, 24px, $color-grayscale-500);
}

.icon--external-link {
  @include svg-icon("#{$get-path-to-icons}external-link.svg", 24px, 24px, $color-grayscale-500);
}

.icon--mail {
  @include svg-icon("#{$get-path-to-icons}mail.svg", 24px, 24px, $color-grayscale-500);
}

.icon--mail-setting {
  @include svg-icon("#{$get-path-to-icons}mail-setting.svg", 24px, 24px, $color-grayscale-500);
}

.icon--print {
  @include svg-icon("#{$get-path-to-icons}print.svg", 24px, 24px, $color-grayscale-500);
}

.icon--eye {
  @include svg-icon("#{$get-path-to-icons}eye.svg", 24px, 24px, $color-grayscale-500);
}

.icon--eye-hide {
  @include svg-icon("#{$get-path-to-icons}eye-hide.svg", 24px, 24px, $color-grayscale-500);
}

.icon--calendar {
  @include svg-icon("#{$get-path-to-icons}calendar.svg", 24px, 24px, $color-grayscale-500);
}

.icon--import {
  @include svg-icon("#{$get-path-to-icons}import.svg", 24px, 24px, $color-grayscale-500);
}

.icon--login {
  @include svg-icon("#{$get-path-to-icons}login.svg", 24px, 24px, $color-grayscale-500);
}

.icon--notification {
  @include svg-icon("#{$get-path-to-icons}notification.svg", 24px, 24px, $color-grayscale-500);
}

.icon--download {
  @include svg-icon("#{$get-path-to-icons}download.svg", 24px, 24px, $color-grayscale-500);
}

.icon--download-gray-700 {
  @include svg-icon("#{$get-path-to-icons}download.svg", 24px, 24px, $color-grayscale-700);
}

.icon--download-file {
  @include svg-icon("#{$get-path-to-icons}download-file.svg", 24px, 24px, $color-grayscale-500);
}

.icon--popup {
  @include svg-icon("#{$get-path-to-icons}popup.svg", 24px, 24px, $color-grayscale-500);
}

.icon--popup-gray-600 {
  @include svg-icon("#{$get-path-to-icons}popup.svg", 24px, 24px, $color-grayscale-600);
}

.icon--company {
  @include svg-icon("#{$get-path-to-icons}company.svg", 24px, 24px, $color-grayscale-500);
}

.icon--chevron-right {
  @include svg-icon("#{$get-path-to-icons}chevron-right.svg", 24px, 24px, $color-grayscale-500);
}

.icon--chevron-expand {
  @include svg-icon("#{$get-path-to-icons}chevron-expand.svg", 24px, 24px, $color-grayscale-500);
}

.icon--chevron-collapse {
  @include svg-icon("#{$get-path-to-icons}chevron-collapse.svg", 24px, 24px, $color-grayscale-500);
}

.icon--chevron-bold-up {
  @include svg-icon("#{$get-path-to-icons}chevron-bold-up.svg", 24px, 24px, $color-platform-300);
}

.icon--chevron-bold-down {
  @include svg-icon("#{$get-path-to-icons}chevron-bold-down.svg", 24px, 24px, $color-grayscale-500);
}

.icon--tree-structure {
  @include svg-icon("#{$get-path-to-icons}tree-structure.svg", 24px, 24px, $color-grayscale-500);
}

.icon--lists--v2 {
  @include svg-icon("#{$get-path-to-icons}lists02.svg", 24px, 24px, $color-grayscale-500);
}

.icon--table--v2 {
  @include svg-icon("#{$get-path-to-icons}table02.svg", 24px, 24px, $color-grayscale-500);
}

.icon--contacts {
  @include svg-icon("#{$get-path-to-icons}contacts.svg", 24px, 24px, $color-grayscale-500);
}

.icon--policy-sent {
  @include svg-icon("#{$get-path-to-icons}policy-sent.svg", 24px, 24px, $color-grayscale-500);
}

.icon--policy-requested {
  @include svg-icon("#{$get-path-to-icons}policy-requested.svg", 24px, 24px, $color-grayscale-500);
}

.icon--policy-add {
  @include svg-icon("#{$get-path-to-icons}policy-add.svg", 24px, 24px, $color-grayscale-500);
}

.icon--offers-uploaded {
  @include svg-icon("#{$get-path-to-icons}offers-uploaded.svg", 24px, 24px, $color-grayscale-500);
}

.icon--details {
  @include svg-icon("#{$get-path-to-icons}details.svg", 24px, 24px, $color-grayscale-500);
}

.icon--checkmark {
  @include svg-icon("#{$get-path-to-icons}checkmark.svg", 24px, 24px, $color-grayscale-500);
}

.icon--indeterminate {
  @include svg-icon("#{$get-path-to-icons}indeterminate.svg", 24px, 24px, $color-grayscale-500);
}

.icon--equals {
  @include svg-icon("#{$get-path-to-icons}equals.svg", 24px, 24px, $color-grayscale-500);
}

.icon--renewal-call {
  @include svg-icon("#{$get-path-to-icons}renewal-call.svg", 24px, 24px, $color-grayscale-500);
}

.icon--insurers {
  @include svg-icon("#{$get-path-to-icons}insurers.svg", 24px, 24px, $color-grayscale-500);
}

.icon--language {
  @include svg-icon("#{$get-path-to-icons}language.svg", 24px, 24px, $color-grayscale-500);
}

.icon--password {
  @include svg-icon("#{$get-path-to-icons}password.svg", 24px, 24px, $color-grayscale-500);
}

.icon--reject-offer {
  @include svg-icon("#{$get-path-to-icons}reject-offer.svg", 24px, 24px, $color-grayscale-500);
}

.icon--time-out {
  @include svg-icon("#{$get-path-to-icons}time-out.svg", 24px, 24px, $color-grayscale-500);
}

.icon--log-out {
  @include svg-icon("#{$get-path-to-icons}log-out.svg", 24px, 24px, $color-grayscale-500);

  &.icon--log-out--small {
    width: 18px;
    height: 18px;
  }
}

.icon--arrow--bottom {
  @include svg-icon("#{$get-path-to-icons}icon--arrow--bottom.svg", 24px, 24px, $color-grayscale-500);
}

.icon--arrow--right {
  @include svg-icon("#{$get-path-to-icons}icon--arrow--right.svg", 24px, 24px, $color-grayscale-500);
}

.icon--dot-small {
  @include svg-icon("#{$get-path-to-icons}dot-small.svg", 24px, 24px, $color-grayscale-500);
}

.icon--move {
  @include svg-icon("#{$get-path-to-icons}move.svg", 24px, 24px, $color-grayscale-500);
}

.icon--pin {
  @include svg-icon("#{$get-path-to-icons}pin.svg", 24px, 24px, $color-grayscale-500);
}

.icon--unpin {
  @include svg-icon("#{$get-path-to-icons}unpin.svg", 24px, 24px, $color-grayscale-500);
}

.icon--state-collapse {
  @include svg-icon("#{$get-path-to-icons}state-collapse.svg", 24px, 24px, $color-grayscale-500);
}

.icon--state-expand {
  @include svg-icon("#{$get-path-to-icons}state-expand.svg", 24px, 24px, $color-grayscale-500);
}

.icon--stop {
  @include svg-icon("#{$get-path-to-icons}stop.svg", 24px, 24px, $color-grayscale-500);
}

.icon--publish {
  @include svg-icon("#{$get-path-to-icons}publish.svg", 24px, 24px, $color-grayscale-500);
}

.icon--paste {
  @include svg-icon("#{$get-path-to-icons}paste.svg", 24px, 24px, $color-grayscale-500);
}

.icon--history {
  @include svg-icon("#{$get-path-to-icons}history.svg", 24px, 24px, $color-grayscale-500);
}

.icon--save {
  @include svg-icon("#{$get-path-to-icons}save.svg", 24px, 24px, $color-grayscale-500);
}

.icon--connection-link {
  @include svg-icon("#{$get-path-to-icons}connection-link.svg", 24px, 24px, $color-grayscale-500);
}

.icon--chart-view {
  @include svg-icon("#{$get-path-to-icons}chart-view.svg", 24px, 24px, $color-grayscale-500);
}

.icon--product-views {
  @include svg-icon("#{$get-path-to-icons}product-views.svg", 24px, 24px, $color-grayscale-500);
}

.icon--note {
  @include svg-icon("#{$get-path-to-icons}note.svg", 24px, 24px, $color-grayscale-500);
}

.icon--note-clock {
  @include svg-icon("#{$get-path-to-icons}note-clock.svg", 24px, 24px, $color-grayscale-500);
}

.icon--image {
  @include svg-icon("#{$get-path-to-icons}image.svg", 24px, 24px, $color-grayscale-500);
}

.icon--minus {
  @include svg-icon("#{$get-path-to-icons}minus.svg", 24px, 24px, $color-grayscale-500);
}

.icon--reset {
  @include svg-icon("#{$get-path-to-icons}reset.svg", 24px, 24px, $color-grayscale-500);
}

.icon--clock {
  @include svg-icon("#{$get-path-to-icons}clock.svg", 24px, 24px, $color-grayscale-500);
}

.icon--circle-slash {
  @include svg-icon("#{$get-path-to-icons}circle-slash.svg", 24px, 24px, $color-grayscale-500);
}

.icon--sms {
  @include svg-icon("#{$get-path-to-icons}sms.svg", 24px, 24px, $color-grayscale-500);
}

.icon--persons-multiple {
  @include svg-icon("#{$get-path-to-icons}persons-multiple.svg", 24px, 24px, $color-grayscale-500);
}

.icon--manual-voting {
  @include svg-icon("#{$get-path-to-icons}manual-voting.svg", 24px, 24px, $color-grayscale-500);
}

.icon--chat-message {
  @include svg-icon("#{$get-path-to-icons}chat-message.svg", 24px, 24px, $color-grayscale-500);
}

.icon--deadline {
  @include svg-icon("#{$get-path-to-icons}deadline.svg", 24px, 24px, $color-grayscale-500);
}

.icon--copy {
  @include svg-icon("#{$get-path-to-icons}copy.svg", 24px, 24px, $color-grayscale-500);
}

.icon--paste {
  @include svg-icon("#{$get-path-to-icons}paste.svg", 24px, 24px, $color-grayscale-500);
}

.icon--bad-request {
  @include svg-icon("#{$get-path-to-icons}bad-request.svg", 24px, 24px, $color-grayscale-500);
}

.icon--fetch {
  @include svg-icon("#{$get-path-to-icons}fetch.svg", 24px, 24px, $color-grayscale-500);
}

.icon--make-offer {
  @include svg-icon("#{$get-path-to-icons}make-offer.svg", 24px, 24px, $color-grayscale-500);
}

.icon--make-offers {
  @include svg-icon("#{$get-path-to-icons}make-offers.svg", 24px, 24px, $color-grayscale-500);
}

.icon--decision-question-b2i {
  background-image: url("#{$get-path-to-icons}decision-question-b2i.svg");
  width: 64px;
  height: 64px;
}

.icon--decision-time {
  background-image: url("#{$get-path-to-icons}decision-time.svg");
  width: 64px;
  height: 64px;
}

.icon--file-pdf {
  background-image: url("#{$get-path-to-icons}pdf.svg");
  width: 32px;
  height: 32px;
}

.icon--file-png {
  background-image: url("#{$get-path-to-icons}png.svg");
  width: 32px;
  height: 32px;
}

.icon--file-jpg {
  background-image: url("#{$get-path-to-icons}jpg.svg");
  width: 32px;
  height: 32px;
}

.icon--file-doc {
  background-image: url("#{$get-path-to-icons}doc.svg");
  width: 32px;
  height: 32px;
}

.icon--file-xls {
  background-image: url("#{$get-path-to-icons}xls.svg");
  width: 32px;
  height: 32px;
}

.icon--nodoc {
  background-image: url("#{$get-path-to-icons}nodoc.svg");
  width: 32px;
  height: 32px;
}

.icon--logo-footer {
  background-image: url("#{$get-path-to-icons}logo-footer.svg");
  width: 109px;
  height: 32px;
}

.icon--logo {
  background-image: url("#{$get-path-to-icons}logo.svg");
  width: 128px;
  height: 38px;
}

.icon--logo--login {
  background-image: url("#{$get-path-to-icons}sobrado-logo--login.svg");
  width: 107px;
  height: 28px;
}
