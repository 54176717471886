// our primary color is *too light*, so mat theme generator forces text font to be black
// to meet contrast accessibility constraints
// see https://m2.material.io/design/color/text-legibility.html#legibility-standards
// there is no way to change it with configs so we have to override manually to match the mocks
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
}
