// Spacing
@use "sass:map";

$spacing: (
  0: 0,
  1: 4px,
  2: 8px,
  3: 16px,
  4: 24px,
  5: 32px,
  6: 40px,
  7: 48px,
  8: 64px,
  9: 80px,
  10: 96px,
  11: 112px,
  12: 128px,
);

$grid-spacing-md: 20px;

$grid-spacing-sm: 10px;

$spacing-default: 8px;

$spacing-10: 10px;

$spacing-12: 12px;

$spacing-18: 18px;

$spacing-20: 20px;

$spacing-default--number: 8;

@function get-spacing($num) {
  @return map.get($spacing, $num);
}
