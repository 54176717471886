@use "../abstracts/variables" as *;
@use "../abstracts/colors" as *;
@use "sass:math";

// Mixin for border-radius
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

// Mixin for border-radius with important rule
@mixin border-radius-important($radius) {
  -webkit-border-radius: $radius !important;
  -moz-border-radius: $radius !important;
  -ms-border-radius: $radius !important;
  border-radius: $radius !important;
}

// Mixin for Transition
@mixin transition($transition) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

// Mixin for text overflow
@mixin textOverflow() {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// Mixin for create icon use svg file
@mixin svg-icon($path, $height, $width, $color) {
  height: $height;
  width: $width;
  background-color: $color;
  -webkit-mask-image: url($path);
  -o-mask-image: url($path);
  -moz-mask-image: url($path);
  mask-image: url($path);
  background-size: cover;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

// Mixin for create icon use svg file with background
@mixin svg-icon-background($path, $height, $width, $color) {
  height: $height;
  width: $width;
  background: $color;
  -webkit-mask-image: url($path);
  -o-mask-image: url($path);
  -moz-mask-image: url($path);
  mask-image: url($path);
  background-size: cover;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

// Mixin for create icon use svg file
@mixin svg-icon-ie($path, $height, $width) {
  height: $height;
  width: $width;
  background-color: transparent !important;
  background-image: url($path);
  background-size: contain;
  background-repeat: no-repeat;
}

// Mixin for create icon use svg file
@mixin only-svg-icon-ie($path) {
  background-color: transparent !important;
  background-image: url($path);
  background-size: contain;
  background-repeat: no-repeat;
}

// Mixin for create blur
@mixin blur($value) {
  -webkit-filter: blur($value);
  -moz-filter: blur($value);
  -o-filter: blur($value);
  -ms-filter: blur($value);
  filter: blur($value);
}

// Mixin for flex box
// align-items: center //
@mixin flex--center--flex-start() {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@mixin flex--center--flex-end() {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@mixin flex--center--center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex--center--space-between() {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex--center--space-around() {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

// align-items: flex-start //
@mixin flex--flex-start--flex-start() {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

@mixin flex--flex-start--flex-end() {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

@mixin flex--flex-start--center() {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

@mixin flex--flex-start--space-between() {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@mixin flex--flex-start--stretch() {
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
}

// align-items: flex-end //
@mixin flex--flex-end--flex-start() {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

@mixin flex--flex-end--flex-end() {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

@mixin flex--flex-end--center() {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

@mixin flex--flex-end--space-between() {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

@mixin flex--flex-end--stretch() {
  display: flex;
  align-items: flex-end;
  justify-content: stretch;
}

// align-items: stretch //
@mixin flex--stretch--flex-start() {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}

@mixin flex--stretch--flex-end() {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
}

@mixin flex--height-stretch() {
  display: flex;
  align-items: stretch;
}

// flex-direction //
@mixin flex--row() {
  display: flex;
  flex-direction: row;
}

@mixin flex--column() {
  display: flex;
  flex-direction: column;
}

@mixin absolute-center() {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

@mixin sobrado-scrollbar(
  $horizontal-icon,
  $vertical-icon,
  $color-scrollbar,
  $color-scrollbar--hover,
  $color-scrollbar-track) {
  // width
  &::-webkit-scrollbar {
    width: 12px;

    &:horizontal {
      height: 12px;
    }

    &:vertical {
      width: 12px;
    }
  }

  // Track
  &::-webkit-scrollbar-track {
    background-color: $color-scrollbar-track;
  }

  // Handle
  &::-webkit-scrollbar-thumb {
    background-image: url($vertical-icon);
    background-size: 6px 11px;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: $color-scrollbar;

    @include border-radius(0);

    &:horizontal {
      background-image: url($horizontal-icon);
      background-size: 11px 6px;
    }

    &:vertical {
      background-image: url($vertical-icon);
      background-size: 6px 11px;
    }

    // Handle on hover
    &:hover {
      background-color: $color-scrollbar--hover;
    }
  }
}

@mixin sobrado-scrollbar-horizontal(
  $horizontal-icon,
  $vertical-icon,
  $color-scrollbar,
  $color-scrollbar--hover,
  $color-scrollbar-track
) {
  // width
  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
    cursor: pointer !important;

    &:horizontal {
      height: 12px;
    }

    &:vertical {
      width: 12px;
    }
  }

  // Track
  &::-webkit-scrollbar-track {
    background-color: $color-scrollbar-track;
    cursor: pointer;
  }

  // Handle
  &::-webkit-scrollbar-thumb {
    background-image: url($horizontal-icon);
    background-size: 11px 6px;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: $color-scrollbar;
    cursor: pointer !important;

    @include border-radius(0);

    &:horizontal {
      background-image: url($horizontal-icon);
      background-size: 11px 6px;
    }

    &:vertical {
      background-image: url($vertical-icon);
      background-size: 6px 11px;
    }

    // Handle on hover
    &:hover {
      background-color: $color-scrollbar--hover;
      cursor: pointer !important;
    }
  }
}

@mixin scrollbar-horizontal-default-hover() {
  // width
  &::-webkit-scrollbar {
    height: 12px;
    cursor: pointer !important;
  }
}

@mixin scrollbar-hover() {
  // width
  &::-webkit-scrollbar {
    width: 6px;
  }

  // Track
  &::-webkit-scrollbar-track {
    background-color: $color-grayscale-100;
  }

  // Handle
  &::-webkit-scrollbar-thumb {
    background-color: $color-grayscale-600;

    @include border-radius(3px);
  }

  // Handle on hover
  &::-webkit-scrollbar-thumb:hover {
    background-color: $color-grayscale-600;
  }
}

@mixin scrollbar-gray-default() {
  // width
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  // Track
  &::-webkit-scrollbar-track {
    background-color: $color-grayscale-100;
  }

  // Handle
  &::-webkit-scrollbar-thumb {
    background-color: $color-grayscale-500;

    @include border-radius(4px);

    // Handle on hover
    &:hover {
      background-color: $color-grayscale-600;
    }
  }
}

@mixin scrollbar-hide() {
  // width
  &::-webkit-scrollbar {
    height: 0 !important;
    width: 0 !important;
  }

  // Track
  &::-webkit-scrollbar-track {
    background-color: transparent !important;
  }

  // Handle
  &::-webkit-scrollbar-thumb {
    background-color: transparent !important;

    @include border-radius(0);

    // Handle on hover
    &:hover {
      background-color: transparent !important;
    }
  }
}

@mixin scrollbar-default($horizontal-icon, $vertical-icon) {
  @include sobrado-scrollbar(
      $horizontal-icon,
      $vertical-icon,
      $color-broker-500,
      $color-broker-600,
      $color-grayscale-100);
}

@mixin scrollbar-insurer($horizontal-icon, $vertical-icon) {
  @include sobrado-scrollbar(
      $horizontal-icon,
      $vertical-icon,
      $color-insurer-500,
      $color-insurer-600,
      $color-grayscale-100);
}

@mixin scrollbar-inter-pension($horizontal-icon, $vertical-icon) {
  @include sobrado-scrollbar(
      $horizontal-icon,
      $vertical-icon,
      $color-inter-pension-500,
      $color-inter-pension-600,
      $color-grayscale-100);
}

@mixin scrollbar-horizontal-default($horizontal-icon, $vertical-icon) {
  @include sobrado-scrollbar-horizontal(
      $horizontal-icon,
      $vertical-icon,
      $color-broker-500,
      $color-broker-600,
      $color-grayscale-100);
}

@mixin scrollbar-horizontal-insurer($horizontal-icon, $vertical-icon) {
  @include sobrado-scrollbar-horizontal(
      $horizontal-icon,
      $vertical-icon,
      $color-insurer-500,
      $color-insurer-600,
      $color-grayscale-100);
}

@mixin scrollbar-horizontal-inter-pension($horizontal-icon, $vertical-icon) {
  @include sobrado-scrollbar-horizontal(
      $horizontal-icon,
      $vertical-icon,
      $color-inter-pension-500,
      $color-inter-pension-600,
      $color-grayscale-100);
}

@mixin draw-progress--solid($progress, $color, $bg-color) {
  background: linear-gradient(to right, $bg-color 50%, $color 50%);

  &::before {
    @if $progress >= 50 {
      background: $color;
      transform: rotate(math.div((100 - (50 - $progress)), 100) * 360deg);
    } @else {
      background: $bg-color;
      transform: rotate(math.div((100 - $progress), 100) * 360deg * -1);
    }
  }
}
