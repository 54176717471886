// unthemable customizaitons
@use "@sng/sng-front-common-style/sass/abstracts/colors" as *;

.mdc-button {
  &.mat-mdc-raised-button {
    --mdc-protected-button-container-shape: 0;
  }

  &.mat-mdc-outlined-button {
    --mdc-outlined-button-container-shape: 0;
  }

}

.mat-mdc-icon-button.icon-button {
  border-radius: 4px;
  border: 1px solid $color-grayscale-200;
}

