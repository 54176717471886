@use "@sng/sng-front-common-style/sass/abstracts/typography" as *;

// can't reuse existing classes because of
// "You may not @extend selectors across media queries" exception

$responsive_breakpoints: (
  sm: 480px,
  md: 640px
);

@mixin with-breakpoints($className) {
  .#{$className} {
    @content
  }

  @each $prefix, $width in $responsive_breakpoints {
    @media (min-width: $width) {
      body .#{$prefix}_#{$className} {
        @content;
      }
    }
  }
}

@mixin spacing-with-breakpoints($baseClassName, $baseClassNameSuffix, $prop, $width) {

  $suffixes: (
    '': '',
    'l': '-left',
    'r': '-right',
    'b': '-bottom',
    't': '-top'
  );

  @each $classInfix, $propSuffix in $suffixes {
    @include with-breakpoints(#{$baseClassName}#{$classInfix}#{$baseClassNameSuffix}) {
      #{$prop}#{$propSuffix} : $width;
    }
  }
}

@include with-breakpoints(hidden) {
  display: none !important;
}

@include with-breakpoints(block) {
  display: block !important;
}

@include with-breakpoints(flex) {
  display: flex !important;
}

@include with-breakpoints(flex-col) {
  flex-direction: column;
}

@include with-breakpoints(flex-row) {
  flex-direction: row;
}

@include with-breakpoints(align-items-center) {
  align-items: center;
}

@include with-breakpoints(align-items-start) {
  align-items: start;
}

@include with-breakpoints(title-3xl) {
  font-size: 48px;
  font-weight: $font-bold;
}

@include with-breakpoints(title-2xl) {
  font-size: $font-2xl;
  font-weight: $font-bold;
}

@include with-breakpoints(title-xl) {
  font-size: $font-xl;
  font-weight: $font-bold;
}

@include with-breakpoints(body-lg) {
  font-size: $font-lg;
  font-weight: $font-bold;
}

@include with-breakpoints(body-md) {
  font-size: $font-md;
}

@include with-breakpoints(body-5xl) {
  font-size: 157px;
}

@include with-breakpoints(body-3xl) {
  font-size: 96px;
}

@include spacing-with-breakpoints('p', '-lg', padding, 20px);
@include spacing-with-breakpoints('p', '-xl', padding, 32px);
@include spacing-with-breakpoints('p', '-xxl', padding, 40px);
@include spacing-with-breakpoints('p', '-3xl', padding, 64px);
@include spacing-with-breakpoints('m', '-lg', margin, 20px);
@include spacing-with-breakpoints('m', '-xl', margin, 32px);
@include spacing-with-breakpoints('m', '-xxl', margin, 40px);
@include spacing-with-breakpoints('m', '-3xl', margin, 64px);
