@use "@sng/sng-front-common-style/sass/abstracts/colors" as *;
@use 'ngx-toastr/toastr';

.toast-success, .toast-error, .toast-info, .toast-warning {
  background-color: white;
  border-top: 2px solid;
  background-image: none;

}

.toast-success {
  border-color: $color-success-500;

}

.toast-error {
  border-color: $color-danger-400;
}

.toast-info {
  border-color: $color-broker-500
}

.toast-warning {
  border-color: $color-accent-400
}

.toast-container .ngx-toastr {
  color: black;
  padding: 0;
}

.toast-container.toast-top-center .ngx-toastr, .toast-container.toast-bottom-center .ngx-toastr {
  max-width: calc(360px - 24px);
  width: calc(100% - 24px);
}

@media (min-width: 390px) {
  .toast-container.toast-top-center .ngx-toastr, .toast-container.toast-bottom-center .ngx-toastr {
    max-width: 360px;
  }
}
