@use "@sng/sng-front-common-style/sass/sass-utils/mixins" as *;
@use "@sng/sng-front-common-style/sass/abstracts/colors" as *;
@use "@sng/sng-front-common-style/sass/abstracts/spacing" as *;
@use "@sng/sng-front-common-style/sass/abstracts/variables" as *;
@use "@sng/sng-front-common-style/sass/abstracts/typography" as *;

$color-tooltip--bg: rgb(0 0 0 / 95%);

@mixin tooltipFont() {
  font-family: $font-family--hk-grotesk;
  font-size: $font-sm;
  font-weight: $font-normal;
  line-height: $leading-sm;
  color: $color-white;
  letter-spacing: inherit;
}

.mdc-tooltip {
  &.mat-mdc-tooltip {
    background: $color-tooltip--bg;
    border-radius: 0 !important;
    padding: get-spacing(0);

    .mdc-tooltip__surface {
      background: $color-tooltip--bg;
      padding: get-spacing(2) $spacing-12 !important;
      border-radius: 0;
      max-width: 280px;

      @include tooltipFont;

      overflow: hidden !important;
    }
  }
}
